import { reactive, readonly, watch } from "vue";
import { load, save } from '@/store/persist';
import uuid from '@/util/uuid';

const SCOPE = 'plans';

const state = reactive({
  userId: null,
  plans: [
    {
      id: 1,
      title: 'Dial In Your Short Game',
      description: 'Hit this plan to dial in your short game so your pin-seeking on every approach.',
      planGames: [
        {
          id: 1,
          gameId: 1,
          config: {
            club: 'SW',
            distance: '65',
          },
        },
        {
          id: 2,
          gameId: 1,
          config: {
            club: 'SW',
            distance: '80',
          },
        },
        {
          id: 3,
          gameId: 1,
          config: {
            club: 'SW',
            distance: '90',
          },
        },
        {
          id: 4,
          gameId: 1,
          config: {
            club: 'PW',
            distance: '100',
          },
        },
        {
          id: 5,
          gameId: 1,
          config: {
            club: 'PW',
            distance: '110',
          },
        },
        {
          id: 6,
          gameId: 1,
          config: {
            club: '9i',
            distance: '130',
          },
        },
        {
          id: 7,
          gameId: 1,
          config: {
            club: '9i',
            distance: '140',
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Off The Tee',
      description: 'Get your tee shots finding fairways easily.',
      planGames: [
        {
          id: 1,
          gameId: 1,
          config: {
            club: 'D',
            distance: '250',
          },
        },
        {
          id: 2,
          gameId: 1,
          config: {
            club: '3W',
            distance: '200',
          },
        },
        {
          id: 3,
          gameId: 1,
          config: {
            club: '5W',
            distance: '190',
          },
        },
      ],
    },
    {
      id: 3,
      title: 'Perfect Putting',
      description: 'Hone your putting skills',
      planGames: [
        {
          id: 1,
          gameId: 6,
          config: {},
        },
        {
          id: 2,
          gameId: 7,
          config: {},
        },
        {
          id: 3,
          gameId: 8,
          config: {},
        },
      ],
    },
  ],
});

watch(state, () => {
  onUpdate();
});

const onUpdate = () => {
  save(state, state.userId, SCOPE);
}

const getPlans = () => {
    return state.plans;
}

const createPlan = (games) => {
  const plan = {
    planId: uuid(),
    userPlan: true,
    games,
  };

  state.plans.push(plan);

  return plan;
};

const getById = (id) => {
    return state.plans.find((g) => g.id === id);
}

const init = (userId) => {
  state.userId = userId;

  Object.assign(state, load(userId, SCOPE));
};

export default {
    state: readonly(state),
    init,
    getPlans,
    getById,
    createPlan,
};