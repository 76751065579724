import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  {
    path: '/',
    component: () => import ('../views/dashboard/Dashboard.vue')
  },
  {
    name: 'GamesHome',
    path: '/games',
    component: () => import ('../views/games/GamesHome.vue')
  },
  {
    name: 'PlansHome',
    path: '/plans',
    component: () => import ('../views/plans/PlansHome.vue')
  },
  {
    name: 'PlanDetails',
    path: '/plan/:id',
    component: () => import ('../views/plans/PlanDetails.vue')
  },
  {
    name: 'GameDetails',
    path: '/game/:id',
    component: () => import ('../views/games/GameDetails.vue')
  },
  {
    name: 'GameConfigure',
    path: '/game/configure/:id',
    component: () => import ('../views/games/GameConfigure.vue')
  },
  {
    name: 'PlanGameDetails',
    path: '/plan/:planId/game/:planGameId',
    component: () => import ('../views/games/PlanGameDetails.vue'),
    props: {
      readOnly: true,
    },
  },
  {
    name: 'PlanGameSession',
    path: '/plan/:planId/game-session/:id',
    component: () => import ('../views/game-sessions/GameSession.vue'),
    props: {
      isPlanGameSession: true,
    },
  },
  {
    name: 'PlanSessionDetails',
    path: '/plan-session/:planSessionId',
    component: () => import ('../views/plan-sessions/PlanSessionDetails.vue'),
  },
  {
    name: 'GameSession',
    path: '/game-session/:id',
    component: () => import ('../views/game-sessions/GameSession.vue')
  },
  {
    name: 'GameSessionsList',
    path: '/game-sessions',
    component: () => import ('../views/game-sessions/GameSessionsHome.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
