import { reactive, readonly, watch } from "vue";
import { load, save } from '@/store/persist';
import uuid from '@/util/uuid';

const SCOPE = 'planSessions';

const state = reactive({
  userId: null,
  planSessions: [],
});

watch(state, () => {
  onUpdate();
});

const onUpdate = () => {
  save(state, state.userId, SCOPE);
}

const createPlanSession = (planId) => {
  const planSession = {
    id: uuid(),
    planId,
    startedAt: new Date(),
    completedAt: null,
  };

  state.planSessions.push(planSession);

  return planSession;
}

const completePlanSession = (planSessionId) => {
  const planSession = getById(planSessionId);

  planSession.completedAt = new Date();

  return planSession;
}

const getPlanSessions = (planId) => {
    return state.planSessions.filter((ps) => ps.planId === planId);
}

const getById = (id) => {
    return state.planSessions.find((ps) => ps.id === id);
}

const init = (userId) => {
  state.userId = userId;

  Object.assign(state, load(userId, SCOPE));
};

export default {
    state: readonly(state),
    init,
    getPlanSessions,
    getById,
    createPlanSession,
    completePlanSession,
};