import { reactive, readonly } from "vue";

const state = reactive({
  games: [
    {
      id: 1,
      title: 'Target Practice',
      description: 'Land your shots as close to a chosen target.',
      image: '',
      skills: ['Accuracy'],
      areas: ['Woods', 'Irons'],
      configs: {
        component: 'TargetPractice',
        statsComponent: 'TargetPracticeStats',
        distance: [90, 100, 110, 120, 130],
        club: ['D', '3W', '3i', '4i', '5i', '6i', '7i', '8i', '9i', 'PW', 'SW'],
      },
      stats: {
        shotCount: true,
        xDev: true,
        yDev: true,
      },
    },
    {
      id: 5,
      title: 'Club Face Positioning',
      description: 'Land your strikes as close to the centre of the club face as possible',
      image: '',
      skills: ['Ball-striking'],
      areas: ['Woods', 'Irons'],
      configs: {
        component: 'ClubFace',
        statsComponent: 'ClubFaceStats',
        club: ['3i', '4i', '5i', '6i', '7i', '8i', '9i', 'PW', 'SW'],
      },
      stats: {
        shotCount: true,
        xDev: true,
        yDev: true,
      },
    },
    {
      id: 6,
      title: 'Consecutive Putts',
      description: 'Make as many consecutive putts from 5 feet as you can.',
      image: '',
      skills: ['Concentration', 'Pressure'],
      areas: ['Putting'],
      configs: {
        component: 'Count',
        statsComponent: 'NumberStats',
      },
      stats: {
        shotCount: true,
      },
    },
    {
      id: 7,
      title: 'Perfect Putt',
      description: 'How many putts do you need to make a Perfect Putt from 3 feet.',
      image: '',
      skills: ['Concentration', 'Pressure'],
      areas: ['Putting'],
      configs: {
        component: 'Count',
        statsComponent: 'NumberStats',
      },
      stats: {
        shotCount: true,
      },
    },
    {
      id: 8,
      title: 'Putt for Points',
      description: 'Increase distance from 1ft to 6ft. 1 point for making it, 3 points for a Perfect Putt. Count up on miss.',
      image: '',
      skills: ['Concentration', 'Pressure'],
      areas: ['Putting'],
      configs: {
        component: 'Points',
        statsComponent: 'NumberStats',
      },
      stats: {
        shotCount: true,
        points: true,
      },
    },

/*
    {
      id: 2,
      title: 'Iron Dispersion Challenge',
      description: 'Score as many points as you can with 30 shots - 100yds, 125yds and 150yds',
      image: '',
      skills: ['Accuracy'],
      areas: ['Irons'],
      configs: {
        component: 'TargetPractice',
        distance: [90, 100, 110, 120, 130],
        club: ['D', '3W', '3i', '4i', '5i', '6i', '7i', '8i', '9i', 'PW', 'SW'],
      }
    },
    {
      id: 3,
      title: 'Flagstick Chipping',
      description: 'How many attempts does it take to hit the flagstick from 5, 10, 15 and 20yards?',
      image: '',
      skills: ['Accuracy'],
      areas: ['Short Game'],
      configs: {
        component: 'TargetPractice',
        club: ['9i', 'PW', 'SW'],
      },
    },
    {
      id: 4,
      title: 'Round the Clock',
      description: 'Putt from clock positions around the hole, aiming to hole each one',
      image: '',
      skills: ['Pressure'],
      areas: ['Putting'],
      configs: {
        component: 'TargetPractice',
      },
    },*/
  ],
});

const getGames = () => {
    return state.games;
}

const getById = (id) => {
    return state.games.find((g) => g.id === id);
}

const getGamesById = (games) => {
  return games.map((gameId) => getById(gameId));
}

const init = () => {};

export default {
    state: readonly(state),
    init,
    getGames,
    getById,
    getGamesById,
};