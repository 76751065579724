import { reactive, readonly, watch } from "vue";
import { load, save } from '@/store/persist';
import uuid from '@/util/uuid';
import games from '@/store/games';
import plans from '@/store/plans';
import gameSessions from '@/store/gameSessions';
import planSessions from '@/store/planSessions';

const SCOPE = 'global';

const state = reactive({
});

watch(state, () => {
  onUpdate();
});

const initUserId = () => {
    let userId = localStorage.getItem('pp-userId');

   if (!userId) {
    userId = uuid();

       localStorage.setItem('pp-userId', userId);
   }

   state.userId = userId;
}

const init = () => {
    initUserId();

    games.init(state.userId);
    plans.init(state.userId);
    gameSessions.init(state.userId);
    planSessions.init(state.userId);

    load(state.userId, SCOPE);
}

const onUpdate = () => {
  save(state, state.userId, SCOPE);
}

export default { 
  state: readonly(state), 
  init,
  games,
  plans,
  gameSessions,
  planSessions,
};