export const clear = (userId) => {
    localStorage.removeItem(`pp-${userId}`);
};

export const save = (data, userId, scope) => {
    clear(userId);

    localStorage.setItem(`pp-${userId}-${scope}`, JSON.stringify(data));
};
export const load = (userId, scope) => {
    const data = localStorage.getItem(`pp-${userId}-${scope}`);
    let state = {};

    try {
        state = JSON.parse(data);
    } catch {} // eslint-disable-line no-empty

    return state;
};