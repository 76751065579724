import { reactive, readonly, watch } from "vue";
import { load, save } from '@/store/persist';
import uuid from '@/util/uuid';
import * as d3 from 'd3';
import numeric from 'numeric';
import jStat from 'jstat';

const SCOPE = 'gameSessions';

const state = reactive({
  userId: null,
  gameSessions: [],
});

watch(state, () => {
  onUpdate();
});

const onUpdate = () => {
  save(state, state.userId, SCOPE);
}

const createGameSession = (gameId, planGameId, planSessionId, config, data) => {
  const gameSession = {
    id: uuid(),
    gameId,
    planGameId,
    planSessionId,
    startedAt: new Date(),
    completedAt: null,
    shots: [],
    config,
    data: data || {},
  };

  state.gameSessions.push(gameSession);

  return gameSession;
}

const addShot = (gameSessionId, shot) => {
  const gameSession = getById(gameSessionId);
  
  gameSession.shots = gameSession.shots || [];

  gameSession.shots.push(shot);

  // recalc stats after each shot
  gameSession.data = {
    ...gameSession.data,
    ..._calculateStats(gameSession),
  };
}

const removeShot = (gameSessionId) => {
  const gameSession = getById(gameSessionId);
  
  gameSession.shots = gameSession.shots || [];

  gameSession.shots.pop();

  // recalc stats after each shot
  gameSession.data = {
    ...gameSession.data,
    ..._calculateStats(gameSession),
  };
}

const completeGameSession = (gameSessionId) => {
  const gameSession = getById(gameSessionId);

  gameSession.data = {
    ...gameSession.data,
    ..._calculateStats(gameSession),
  };

  gameSession.completedAt = new Date();

  return completeGameSession;
}

const getById = (id) => {
    return state.gameSessions.find((gs) => gs.id === id);
}

const getByGameId = (gameId) => {
  return state.gameSessions.find((gs) => gs.gameId === gameId);
}

const getAllByGameId = (gameId) => {
  return state.gameSessions.filter((gs) => gs.gameId === gameId);
}

const getByGameIdAndPlanSessionId = (gameId, planSessionId) => {
  return state.gameSessions.find((gs) => gs.gameId === gameId && gs.planSessionId === planSessionId);
}

const getByPlanGameIdAndPlanSessionId = (planGameId, planSessionId) => {
  return state.gameSessions.find((gs) => gs.planGameId === planGameId && gs.planSessionId === planSessionId);
}

const getGameSessionIndex = (gameSessionId) => {
  return state.gameSessions.findIndex((gs) => gs.id === gameSessionId);
}

const removeGameSession = (gameSessionId) => {
  const index = getGameSessionIndex(gameSessionId);

  if (index >= 0) {
    state.gameSessions.splice(index, 1);
  }
}

const _calculateStats = (gameSession) => {
  // TODO: only calculate the stats applicable to the game type
  const xs = gameSession.shots.map((pt) => pt.xAbs);
  const ys = gameSession.shots.map((pt) => pt.yAbs);

  let ellipse = null;

  try {
    ellipse = gameSession.shots.length < 3 ? null : _calculateEllipse(xs, ys)
  } catch (e) {
    ellipse = null;
  }

  return {
    xDev: xs.length > 0 ? _calculateStandardDeviation(xs) : 0,
    yDev: ys.length > 0 ? _calculateStandardDeviation(ys) : 0,
    ellipse,
  };
}


const _indexOfMax = function(data) {
  return data.indexOf(Math.max.apply(null, data));
};

const _indexOfMin = function(data) {
  return data.indexOf(Math.min.apply(null, data));
};

const _calculateEllipseDefinition = function(stdDevX, stdDevY, cor, center, level) {
  const cov = cor * stdDevX * stdDevY;
  const covmat = [
    [stdDevX * stdDevX, cov],
    [cov, stdDevY * stdDevY]
  ];
  const eig = numeric.eig(covmat);
  const scale = Math.sqrt(jStat.chisquare.inv(level, 2));
  const maxLambdaI = _indexOfMax(eig.lambda.x);
  const minLambdaI = _indexOfMin(eig.lambda.x);
  const rx = stdDevX > stdDevY ? Math.sqrt(eig.lambda.x[maxLambdaI]) * scale : Math.sqrt(eig.lambda.x[minLambdaI]) * scale;
  const ry = stdDevY > stdDevX ? Math.sqrt(eig.lambda.x[maxLambdaI]) * scale : Math.sqrt(eig.lambda.x[minLambdaI]) * scale;
  const v1 = eig.E.x[maxLambdaI];
  let theta =  Math.atan2(v1[1], v1[0]);
    
  if (theta < 0) {
    theta += 2 * Math.PI;
  }

  return {
    rx: rx,
    ry: ry,
    cx: center.x,
    cy: center.y,
    orient: -(theta * 180 / Math.PI)
  };
};

const _calculateEllipse = (xData, yData) => {
  const xDataDev = d3.deviation(xData);
  const xMean = d3.mean(xData);
  const xExtent = d3.extent(xData);

  const yMean = d3.mean(yData);
  const yDataDev = d3.deviation(yData);
  const yExtent = d3.extent(yData);
  const cor = jStat.corrcoeff(xData, yData);

  const userEllipse = _calculateEllipseDefinition(xDataDev,  yDataDev, cor, {
    x: xMean,
    y: yMean
  }, 0.99);

  return {
    ...userEllipse,
    xExtent,
    yExtent,
  };
}

const _calculateStandardDeviation = (points) => {
  const size = 300;
  const pointsRel = points.map((p) => (size / 2) - p);

  const total = pointsRel.reduce((out, p) => p + out, 0);
  const mean = total / pointsRel.length;

  const meanDistances = pointsRel.map((p) => Math.pow((p - mean), 2));

  const sum = meanDistances.reduce((out, v) => v + out, 0);

  const dev = Math.sqrt(sum / pointsRel.length);

  return Math.round(dev * 10) / 10;
};

const init = (userId) => {
  state.userId = userId;

  Object.assign(state, load(userId, SCOPE));
};

export default {
    state: readonly(state),
    init,
    getById,
    getByGameId,
    getByGameIdAndPlanSessionId,
    getByPlanGameIdAndPlanSessionId,
    getAllByGameId,
    createGameSession,
    addShot,
    removeShot,
    removeGameSession,
    completeGameSession,
    _calculateEllipse,
};